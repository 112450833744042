import { useRef } from 'react';
import { JobTypeLResponse } from '@doda/api-client/aspida/@types';
import { transformAreas, transformJobTypes } from '../logics/function/prepare';
import { AreaListOfSearchModal } from '../../../models/MasterCacheModel';

interface Props {
  /**
   * 職種マスタの実行結果
   * API: /api/v1/jobType
   */
  jobTypes: JobTypeLResponse[];
  /**
   * 地域・都道府県・市区町村・政令指定都市マスタの実行結果
   * API: /api/v1/area
   */
  areas: AreaListOfSearchModal[];
}

/**
 * 希望条件モーダルのState管理を行う
 */
export const useMatchJob = ({ jobTypes, areas }: Props) => {
  const jobDialogReference = useRef<HTMLDialogElement>(null);
  const salaryDialogReference = useRef<HTMLDialogElement>(null);

  const jobCategories = transformJobTypes(jobTypes);
  const workplaces = transformAreas(areas);

  return {
    jobDialogReference,
    salaryDialogReference,
    jobCategories,
    workplaces,
  };
};
