import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  LocationContext,
  OccupationContext,
  SalaryContext,
} from './logics/context';
import { Selection } from './type';

interface Props {
  children: React.ReactNode;
}

export const MatchJobProvider = ({ children }: Props) => {
  const [occupations, setOccupations] = useState<Selection[]>([]);
  const [locations, setLocations] = useState<Selection[]>([]);
  const [salary, setSalary] = useState<Selection>({ name: '', value: '' });
  const selectionOccupation = useMemo<
    [Selection[], Dispatch<SetStateAction<Selection[]>>]
  >(() => [occupations, setOccupations], [occupations]);
  const selectionLocation = useMemo<
    [Selection[], Dispatch<SetStateAction<Selection[]>>]
  >(() => [locations, setLocations], [locations]);
  const selectionSalary = useMemo<
    [Selection, Dispatch<SetStateAction<Selection>>]
  >(() => [salary, setSalary], [salary]);

  return (
    <OccupationContext.Provider value={selectionOccupation}>
      <LocationContext.Provider value={selectionLocation}>
        <SalaryContext.Provider value={selectionSalary}>
          {children}
        </SalaryContext.Provider>
      </LocationContext.Provider>
    </OccupationContext.Provider>
  );
};
