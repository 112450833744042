import { MouseEventHandler } from 'react';
import {
  Button,
  LeftBorderTitle,
  OutlineTag,
  Text,
} from '@doda/io-react/common';
import classNames from 'classnames';
import styles from './index.module.scss';

/** SelectedItemsDisplayコンポーネントのProps */
interface Props {
  /**
   * 見出し
   */
  heading: string;
  /**
   * 選択した選択項目名
   */
  labels: string[];
  /**
   * 追加のStyleを受け取る
   */
  customStyle?: string;
  /**
   * クリックイベント
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
  /**
   * 希望順のラベルを制御する
   */
  displayLabel: boolean;
}

/**
 * 選択した項目名を表示する
 *
 * @param {Props} props 制御項目
 */
export const SelectedItemsDisplay: React.FC<Props> = ({
  heading,
  labels,
  customStyle,
  onClick,
  displayLabel,
}: Props) => {
  const baseStyle = classNames(styles.box, customStyle);

  return (
    <div className={baseStyle}>
      <div className={styles.box__header}>
        <LeftBorderTitle as="header">{heading}</LeftBorderTitle>
        <Button
          size="S"
          variant="outline_gray"
          as="button"
          onClick={onClick}
          className={styles.box__button}
        >
          変更する
        </Button>
      </div>

      {labels.length > 0 ? (
        labels.map((label, index) => (
          <div key={index} className={styles.box__content}>
            {displayLabel && (
              <OutlineTag color="lightGreen" className={styles.box__tag}>
                第{index + 1}希望
              </OutlineTag>
            )}
            <Text size="14--articlePC" color="black_pc" as="span">
              {label}
            </Text>
          </div>
        ))
      ) : (
        <div className={styles.box__content}>
          <Text size="14--articlePC" color="black_pc" as="span">
            選択してください
          </Text>
        </div>
      )}
    </div>
  );
};
