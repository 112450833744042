import { Selection, JobType, SubCategory } from '../type';

/**
 * 大分類の職種の見出しに「選択中」を表示するかの判断結果を提供する
 *
 * 以下の条件の場合に「選択中」の文言を表示させる
 *
 * (1) {職種}すべてを選択した場合
 *
 * (2) {中分類の職種}すべてを選択した場合
 *
 * (3) {小分類の職種}を選択した場合
 *
 * @param items 選択中の項目群
 * @param content 職種
 * @returns true:表示, false:非表示
 */
export const handleIsSelectedHeader = (
  items: Selection[],
  content: JobType
): boolean => {
  if (items.length === 0) {
    return false;
  }

  // 大分類の職種が含まれているか検証
  if (items.some((item) => item.value === content.id)) {
    return true;
  }

  // 中分類の職種群を生成して含まれているか検証
  const subCategories: string[] = content.subCategories.map((item) => item.id);

  if (
    items.some(
      (item) => item.value === content.id || subCategories.includes(item.value)
    )
  ) {
    return true;
  }

  // 小分類の職種群を生成して含まれているか検証
  const jobTypes: string[] = content.subCategories.flatMap(
    (subCategory: SubCategory) => subCategory.minors.map((item) => item.id)
  );
  return items.some(
    (item) =>
      item.value === content.id ||
      subCategories.includes(item.value) ||
      jobTypes.includes(item.value)
  );
};

/**
 * 中分類の職種の見出しに「選択中」を表示するかの判断結果を提供する
 *
 * 以下の条件の場合に「選択中」の文言を表示させる
 *
 * (1) {中分類の職種}すべてを選択した場合
 *
 * (2) {小分類の職種}を選択した場合
 *
 * @param items 選択中の項目群
 * @param content 職種
 * @returns true:表示, false:非表示
 */
export const handleIsSelectedItem = (
  items: Selection[],
  content: SubCategory
): boolean => {
  if (items.length === 0) {
    return false;
  }

  // 小分類の職種群を生成して含まれているか検証
  const jobTypes: string[] = content.minors.map((item) => item.id);
  return items.some(
    (item) => item.value === content.id || jobTypes.includes(item.value)
  );
};
