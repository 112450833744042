import { Text } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * タイトルコンポーネントのProps
 */
interface Props {
  /** タイトルのタイプ */
  type: string;
  /** 最大件数 */
  limit: number;
}

/**
 * タイトル
 *
 * @param {Props} props 構築情報
 */
export const Title: React.FC<Props> = ({ type, limit }: Props) => {
  return (
    <div className={styles.title}>
      <Text
        color="black_pc"
        as="span"
        size="14--articlePC"
        className={styles.title__text}
      >
        <Text color="black_pc" as="span" weight="bold">
          {type}
        </Text>
        を教えてください
      </Text>
      <Text color="black_pc" size="14--articlePC">
        (最大{limit}件)
      </Text>
    </div>
  );
};
