import { RefObject } from 'react';
import { JobTypeLResponse } from '@doda/api-client/aspida/@types';
import { AreaListOfSearchModal } from '@doda/common/src/models/MasterCacheModel';
import { contents } from '@doda/common/src/components/DesiredConditionsModal/contents';
import { useMatchJob } from '@doda/common/src/components/DesiredConditionsModal/hooks/useMatchJob';
import { MatchJobProvider } from '@doda/common/src/components/DesiredConditionsModal/MatchJobProvider';
import Result from './components/Result';
import { Occupation } from './components/Occupation';
import { Location } from './components/Location';
import { IncomeStartupMode } from './components/IncomeStartupMode';

/**
 * 希望条件変更モーダルのエントリーポイントのProps
 */
interface Props {
  /**
   * dialog要素の参照情報
   */
  dialogReference: RefObject<HTMLDialogElement>;
  /**
   * 職種マスタの実行結果
   * API: /api/v1/jobType
   */
  jobTypes: JobTypeLResponse[];
  /**
   * 地域・都道府県・市区町村・政令指定都市マスタの実行結果
   * API: /api/v1/area
   */
  areas: AreaListOfSearchModal[];
  /**
   * 初回のヒアリング状態で起動するかどうか
   *
   * 初回のヒアリング状態にするならtrueを指定する
   */
  startupMode?: boolean;
}

/**
 * 希望条件変更モーダルのエントリーポイント
 */
export const MatchJobDialog: React.FC<Props> = ({
  dialogReference,
  jobTypes,
  areas,
  startupMode = false,
}: Props) => {
  const {
    jobDialogReference,
    salaryDialogReference,
    jobCategories,
    workplaces,
  } = useMatchJob({ jobTypes, areas });

  return (
    <MatchJobProvider>
      {startupMode ? (
        <>
          <Location
            selefDialogReference={dialogReference}
            controlledDialogReference={jobDialogReference}
            workplaces={workplaces}
            limit={5}
            startupMode={startupMode}
          />

          <Occupation
            selefDialogReference={jobDialogReference}
            controlledDialogReference={salaryDialogReference}
            backedDialogReference={dialogReference}
            jobTypes={jobCategories}
            limit={3}
            startupMode={startupMode}
          />

          <IncomeStartupMode
            selefDialogReference={salaryDialogReference}
            controlledDialogReference={dialogReference}
            backedDialogReference={jobDialogReference}
            salaries={contents}
            startupMode={startupMode}
          />
        </>
      ) : (
        <Result
          dialogReference={dialogReference}
          jobCategories={jobCategories}
          workplaces={workplaces}
          salaries={contents}
          startupMode={startupMode}
        />
      )}
    </MatchJobProvider>
  );
};
