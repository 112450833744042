import { convertToDesiredCondition } from '..';
import {
  DesiredCondition,
  FormattedDesiredCondition,
  Selection,
} from '../../../type';
import {
  getDesiredConditionSynchronize,
  postDesiredConditionCookie,
} from '../api';

const defaultCondition: FormattedDesiredCondition = {
  areas: {
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
  },
  income: '',
  jobs: {
    1: '',
    2: '',
    3: '',
  },
  update: '',
};

/**
 * cookieから希望条件を取得して加工した結果を提供する
 *
 * @param key キー
 * @returns 加工した希望条件
 */
export const derivationFormattedDesiredCondition = (
  key: string
): FormattedDesiredCondition => {
  const desiredCondition = document.cookie.split(';').find((cookie) => {
    return cookie.trim().startsWith(`${key}=`);
  });

  return desiredCondition
    ? parseDesiredCondition(decodeURIComponent(desiredCondition.split('=')[1]))
    : { ...defaultCondition, update: new Date().getTime().toString() };
};

/**
 * cookie(desired_cond)のキーに紐づくJsonオブジェクトを読み込む
 *
 * 読み込み時にskipを空文字として解釈し、空文字へ置換する
 *
 * skipが付与している場合、DBに登録している希望条件を優先とする
 * 当機能では、マージ機能は対象範囲外
 *
 * @param source cookie(desired_cond)
 * @returns パースしたJsonオブジェクト
 */
const parseDesiredCondition = (source: string): FormattedDesiredCondition => {
  const parsedObject: DesiredCondition = JSON.parse(source);
  // area1のみ対象
  const area = convertToEmptyIfNullOrSkip(parsedObject.area1);
  // job1のみ対象
  const job = convertToEmptyIfNullOrSkip(parsedObject.job1);
  // income対象
  const income = convertToEmptyIfNullOrSkip(parsedObject.income);
  return {
    areas: {
      1: area,
      2: parsedObject.area2,
      3: parsedObject.area3,
      4: parsedObject.area4,
      5: parsedObject.area5,
    },
    income,
    jobs: {
      1: job,
      2: parsedObject.job2,
      3: parsedObject.job3,
    },
    update: parsedObject.update,
  };
};

/**
 * jsonの値がnull,skipの値の場合は空文字として解釈する
 *
 * @param target 検査対象
 * @returns 検査結果
 */
const convertToEmptyIfNullOrSkip = (target: string) => {
  const inspections = [`null`, `skip`];
  return inspections.includes(target) ? '' : target;
};

/**
 * キーを基に希望条件を更新・設定する
 *
 * @param key キー
 * @param value 希望条件
 * @param domain ドメイン
 * @param path パス
 */
export const setCookie = (value: DesiredCondition): void => {
  postDesiredConditionCookie(value);
  getDesiredConditionSynchronize();
};

/**
 * キーに紐づいた希望職種を更新する
 *
 * 条件を満たしていない場合は、何もしない
 *
 * @param value Jsonオブジェクト
 * @param day 日付
 * @param domain ドメイン
 * @param path パス
 * @param key Cookieのキー
 * @param items 選択項目
 * @param startupMode 初回ヒアリング状態
 */
export const updateOccupationCookie = (
  key: string,
  items: Selection[],
  startupMode: boolean
): void => {
  const formattedDesiredCondition: FormattedDesiredCondition | null =
    derivationFormattedDesiredCondition(key);
  if (formattedDesiredCondition !== null) {
    const updateFormattedDesiredCondition: FormattedDesiredCondition =
      updateJobs(formattedDesiredCondition, items, startupMode);
    const desiredCondition = convertToDesiredCondition(
      updateFormattedDesiredCondition
    );
    postDesiredConditionCookie(desiredCondition);
  }
};

/**
 * 初回ヒアリングで未選択の場合はskipを与える
 *
 * @param formattedDesiredCondition 希望条件
 * @param items 選択結果
 * @param startupMode 初回のヒアリング状態にするならtrueを指定する
 * @returns 更新結果
 */
const updateJobs = (
  formattedDesiredCondition: FormattedDesiredCondition,
  items: Selection[],
  startupMode: boolean
) => {
  let selections: Selection[] = [];
  if (startupMode && items.length === 0) {
    selections = [{ name: '', value: 'skip' }];
  } else {
    selections = items;
  }

  const { areas } = formattedDesiredCondition;
  if (startupMode && areas[1] === '') {
    areas[1] = 'skip';
  }

  let { income } = formattedDesiredCondition;
  if (startupMode && income.length === 0) {
    income = 'skip';
  }

  return {
    areas,
    income,
    jobs: {
      1: selections[0] ? selections[0].value : '',
      2: selections[1] ? selections[1].value : '',
      3: selections[2] ? selections[2].value : '',
    },
    update: new Date().getTime().toString(),
  };
};

/**
 * キーに紐づいた希望勤務地を更新する
 *
 * @param key キー
 * @param items 選択結果
 * @param path パス
 * @param startupMode 初回のヒアリング状態にするならtrueを指定する
 */
export const updateLocationCookie = (
  key: string,
  items: Selection[],
  startupMode: boolean
): void => {
  const formattedDesiredCondition: FormattedDesiredCondition | null =
    derivationFormattedDesiredCondition(key);
  if (formattedDesiredCondition !== null) {
    const updateFormattedDesiredCondition: FormattedDesiredCondition =
      updateLocations(formattedDesiredCondition, items, startupMode);
    const desiredCondition = convertToDesiredCondition(
      updateFormattedDesiredCondition
    );
    postDesiredConditionCookie(desiredCondition);
  }
};

/**
 * 初回ヒアリングで未選択の場合はskipを与える
 *
 * @param formattedDesiredCondition 希望条件
 * @param items 選択結果
 * @param startupMode 初回のヒアリング状態にするならtrueを指定する
 * @returns 更新結果
 */
const updateLocations = (
  formattedDesiredCondition: FormattedDesiredCondition,
  items: Selection[],
  startupMode: boolean
) => {
  let { income } = formattedDesiredCondition;
  if (startupMode && income.length === 0) {
    income = 'skip';
  }

  const { jobs } = formattedDesiredCondition;
  if (startupMode && jobs[1] === '') {
    jobs[1] = 'skip';
  }

  let selections: Selection[] = [];
  if (startupMode && items.length === 0) {
    selections = [{ name: '', value: 'skip' }];
  } else {
    selections = items;
  }

  return {
    areas: {
      1: selections[0] ? selections[0].value : '',
      2: selections[1] ? selections[1].value : '',
      3: selections[2] ? selections[2].value : '',
      4: selections[3] ? selections[3].value : '',
      5: selections[4] ? selections[4].value : '',
    },
    income,
    jobs,
    update: new Date().getTime().toString(),
  };
};

/**
 * キーに紐づいた希望年収を更新する
 *
 * 条件を満たしていない場合は、何もしない
 *
 * @param key キー
 * @param item 選択結果
 * @param startupMode 初回のヒアリング状態にするならtrueを指定する
 */
export const updateIncomeCookie = async (
  key: string,
  item: Selection,
  startupMode: boolean
) => {
  const formattedDesiredCondition: FormattedDesiredCondition | null =
    derivationFormattedDesiredCondition(key);
  if (formattedDesiredCondition !== null) {
    const updateFormattedDesiredCondition: FormattedDesiredCondition =
      updateIncome(formattedDesiredCondition, item, startupMode);
    const desiredCondition = convertToDesiredCondition(
      updateFormattedDesiredCondition
    );
    await postDesiredConditionCookie(desiredCondition);
  }
};

/**
 * 初回ヒアリングで未選択の場合はskipを与える
 *
 * @param formattedDesiredCondition 希望条件
 * @param item 選択結果
 * @param startupMode 初回のヒアリング状態にするならtrueを指定する
 * @returns 更新結果
 */
const updateIncome = (
  formattedDesiredCondition: FormattedDesiredCondition,
  item: Selection,
  startupMode: boolean
) => {
  const { areas } = formattedDesiredCondition;
  if (startupMode && areas[1] === '') {
    areas[1] = 'skip';
  }

  const { jobs } = formattedDesiredCondition;
  if (startupMode && jobs[1] === '') {
    jobs[1] = 'skip';
  }

  let selection: Selection = { name: '', value: '' };
  if (startupMode && item.value === '') {
    selection = { name: '', value: 'skip' };
  } else {
    selection = item;
  }

  return {
    areas,
    income: selection.value,
    jobs,
    update: new Date().getTime().toString(),
  };
};
