import React, { createContext, useContext, useState } from 'react';

export function createOptimizedContext<T>(initialValue: T) {
  const ValueContext = createContext<T>(null as never);
  const SetterContext = createContext<React.Dispatch<React.SetStateAction<T>>>(
    null as never
  );
  const useValueContext = () => useContext(ValueContext);
  const useSetterContext = () => useContext(SetterContext);

  function Provider({ children }: { children: React.ReactNode }) {
    const [value, setValue] = useState(initialValue);
    return (
      <ValueContext.Provider value={value}>
        <SetterContext.Provider value={setValue}>
          {children}
        </SetterContext.Provider>
      </ValueContext.Provider>
    );
  }

  return [Provider, useValueContext, useSetterContext] as const;
}
