import { MutableRefObject, useContext, useEffect, useState } from 'react';
import { Income, JobType, Selection, Workplace } from '../type';
import { OccupationContext, LocationContext, SalaryContext } from './context';
import { pickUpJobTypes, pickUpAreas, pickUpIncome } from './function';
import { derivationFormattedDesiredCondition } from './function/cookie';

/**
 * 現在選択している項目の出し入れを行うカスタムフック
 *
 * 後入れで追加を行う。
 *
 * 配列の要素順は希望選択順と一致する。
 *
 * @param initial 選択項目
 * @returns 選択中の項目
 */
export const useSelection = (initial: Selection[]) => {
  const [items, setItems] = useState<Selection[]>(initial);

  const toggleSelection = (checkBoxValue: Selection) => {
    const updatedChosenItem = [...items];
    const index = updatedChosenItem.findIndex(
      (item) => item.value === checkBoxValue.value
    );

    if (index === -1) {
      updatedChosenItem.push(checkBoxValue);
    } else {
      updatedChosenItem.splice(index, 1);
    }
    setItems(updatedChosenItem);
  };
  return { selectedItems: items, toggleSelection, setItems };
};

/**
 * 選択している項目の出し入れを行うカスタムフック
 *
 * useStateの非同期更新が間に合わないので選択した項目を返すようにしている
 *
 * @param initial 選択項目
 * @returns 選択中の項目
 */
export const useChange = (initial: Selection) => {
  const [item, setItem] = useState<Selection>(initial);

  const toggleSelection = (selection: Selection) => {
    const selectedItem = selection;
    if (selectedItem.value === '') {
      const defaultItem = { name: '選択してください', value: '' };
      setItem(defaultItem);
      return defaultItem;
    }
    setItem(selectedItem);
    return selectedItem;
  };
  return { selectedItem: item, toggleSelection, setItem };
};

/**
 * Escキーを押した時に、ダイアログを閉じるカスタムフック
 *
 * @param dialog 現在のダイアログ
 * @param parentDialog 親にあたるダイアログ
 */
export const useEscapeClose = (
  dialog: MutableRefObject<HTMLDialogElement | null>,
  parentDialog: MutableRefObject<HTMLDialogElement | null>
) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        document.body.classList.remove('scroll-lock');
        dialog.current?.close();
        parentDialog.current?.close();
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dialog, parentDialog]);
};

interface UpdateStateResult {
  /**
   * Cookieの値を基に選択項目を設定する関数
   *
   * @returns {Promise<void>} void
   */
  updateStateFromCookie: () => Promise<void>;
  /**
   * 職種の選択状態
   */
  occupations: Selection[];
  /**
   * 勤務地の選択状態
   */
  locations: Selection[];
  /**
   * 希望年収の選択状態
   */
  salary: Selection;
}

/**
 * Cookieと加工したマスタデータを基に選択状態を復元するカスタムフック
 *
 * @param jobCategories 加工した職種データ
 * @param workplaces 加工した地域データ
 * @param salaries 希望年収データ
 * @returns {UpdateStateResult} 参照用のuseStateとcookieから取得した結果を設定する関数
 */
export const useUpdateStateFromCookie = (
  jobCategories: JobType[],
  workplaces: Workplace[],
  salaries: Income[]
): UpdateStateResult => {
  const [occupations, setOccupations] = useContext(OccupationContext);
  const [locations, setLocations] = useContext(LocationContext);
  const [salary, setSalary] = useContext(SalaryContext);

  const updateStateFromCookie = async () => {
    const cookie = derivationFormattedDesiredCondition('desired_cond');
    const pickedJobTypes = pickUpJobTypes(cookie, jobCategories);
    const pickedAreas = pickUpAreas(cookie, workplaces);
    const pickedSalary = pickUpIncome(cookie, salaries);

    setOccupations(pickedJobTypes);
    setLocations(pickedAreas);
    setSalary(pickedSalary);
  };

  return {
    updateStateFromCookie,
    occupations,
    locations,
    salary,
  };
};
