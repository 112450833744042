import { RefObject, useContext, useEffect } from 'react';
import {
  AccordionList,
  TopAccordionListItem,
  BottomAccordionCheckListItem,
} from '@doda/io-react/common';
import { adobeAnalyticsScriptsSelector } from '@doda/common/src/redux/features/analytics';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@doda/common/src/redux/hooks';
import {
  useEscapeClose,
  useSelection,
} from '@doda/common/src/components/DesiredConditionsModal/logics/hook';
import {
  handleSubmitLocations,
  handleCancel,
} from '@doda/common/src/components/DesiredConditionsModal/logics/event';
import { handleIsSelectedItem } from '@doda/common/src/components/DesiredConditionsModal/logics/location';
import {
  isSelectedItem,
  numericSelection,
} from '@doda/common/src/components/DesiredConditionsModal/logics/function';
import { LocationContext } from '@doda/common/src/components/DesiredConditionsModal/logics/context';
import { measureOnClickLocation } from '@doda/common/src/components/DesiredConditionsModal/logics/function/analytics';
import { Workplace } from '@doda/common/src/components/DesiredConditionsModal/type';
import styles from '../../index.module.scss';
import { Item } from '../Item';
import { BottomButton } from '../BottomButton';
import { CancelButton } from '../CancelButton';
import { Title } from '../Title';
import { BackButton } from '../BackButton';

/** LocationコンポーネントのProps */
interface Props {
  /**
   * ダイアログ参照情報
   */
  selefDialogReference: RefObject<HTMLDialogElement>;
  /**
   * 制御するダイアログ参照情報
   */
  controlledDialogReference: RefObject<HTMLDialogElement>;
  /**
   * 加工した地域データ
   */
  workplaces: Workplace[];
  /**
   * 最大選択数
   */
  limit: number;
  /**
   * 初回のヒアリング状態で起動するかどうか
   *
   * 初回のヒアリング状態にするならtrueを指定する
   */
  startupMode: boolean;
}

/**
 * 勤務地候補選択ダイアログ
 */
export const Location: React.FC<Props> = ({
  selefDialogReference,
  controlledDialogReference,
  workplaces,
  limit,
  startupMode,
}: Props) => {
  const { selectedItems, toggleSelection, setItems } = useSelection([]);
  const [locations, setLocations] = useContext(LocationContext);
  const dispatch = useAppDispatch();
  const hasAdobeAnalyticsScripts = useSelector(adobeAnalyticsScriptsSelector);

  useEscapeClose(selefDialogReference, controlledDialogReference);

  useEffect(() => {
    setItems(locations);
  }, [locations, setItems]);

  return (
    <dialog id="location" ref={selefDialogReference} className={styles.dialog}>
      <div className={styles.dialog__header}>
        <Title type="希望の勤務地" limit={limit} />
        <div className={styles.dialog__header__right}>
          <CancelButton
            onClick={() => {
              handleCancel(
                () => selefDialogReference.current?.close(),
                () => controlledDialogReference.current?.close()
              );
            }}
          />
        </div>
      </div>

      <div
        className={`${styles.dialog__layout} ${
          styles[`selection-dialog__layout`]
        }`}
      >
        {!startupMode && (
          <div className={styles[`selection-dialog__content-upper`]}>
            <BackButton
              onClick={() => {
                selefDialogReference.current?.close();
              }}
            />
          </div>
        )}

        <AccordionList
          boxShadow="blue"
          className={`${styles.dialog__content} ${
            styles[`selection-dialog__content`]
          }`}
        >
          {workplaces.map((content, index) => {
            return (
              <TopAccordionListItem
                heading={content.header}
                key={index}
                selected={handleIsSelectedItem(selectedItems, content)}
              >
                <BottomAccordionCheckListItem
                  heading={
                    <Item
                      name={`${content.header}すべて`}
                      current={numericSelection(selectedItems, content.value)}
                      limit={limit}
                    />
                  }
                  value=""
                  checked={isSelectedItem(selectedItems, content.value)}
                  onChange={() =>
                    toggleSelection({
                      name: content.header,
                      value: content.value,
                    })
                  }
                  className={
                    numericSelection(selectedItems, content.value) > limit
                      ? styles[`selection-dialog__content__item--error`]
                      : styles[`selection-dialog__content__item`]
                  }
                />

                {content.places.map((item) => {
                  return (
                    <BottomAccordionCheckListItem
                      key={item.value}
                      heading={
                        <Item
                          name={item.name}
                          current={numericSelection(selectedItems, item.value)}
                          limit={limit}
                        />
                      }
                      value=""
                      checked={isSelectedItem(selectedItems, item.value)}
                      onChange={() =>
                        toggleSelection({ name: item.name, value: item.value })
                      }
                      className={
                        numericSelection(selectedItems, item.value) > limit
                          ? styles[`selection-dialog__content__item--error`]
                          : styles[`selection-dialog__content__item`]
                      }
                    />
                  );
                })}
              </TopAccordionListItem>
            );
          })}
        </AccordionList>
      </div>

      <BottomButton
        onClick={() => {
          if (startupMode) {
            controlledDialogReference.current?.showModal();
            measureOnClickLocation(hasAdobeAnalyticsScripts, dispatch);
          }
          handleSubmitLocations(
            () => selefDialogReference.current?.close(),
            selectedItems,
            setLocations,
            startupMode
          );
        }}
        current={selectedItems.length}
        limit={limit}
        startupMode={startupMode}
      />
    </dialog>
  );
};
