import {
  DesiredCondition,
  FormattedDesiredCondition,
  Income,
  JobType,
  Selection,
  Workplace,
} from '../../type';

/**
 * 対象の値が選択中の項目群のどの位置なのかを提供する
 *
 * @param items 選択中の項目
 * @param value 項目
 * @returns 選択中の項目の位置
 */
export const numericSelection = (items: Selection[], value: string): number => {
  const index = items.findIndex((item) => item.value === value);
  return index !== -1 ? index + 1 : -1;
};

/**
 * 選択項目の名称を提供する
 *
 * @param items 選択項目
 * @returns 名称
 */
export const displayName = (items: Selection[]): string[] => {
  return items.map((item) => item.name);
};

/**
 * cookieから取得した値を基に選択した希望職種の導出結果を提供する
 *
 * @param condition 希望条件
 * @param jobCategories 職種候補
 * @returns 選択項目
 */
export const pickUpJobTypes = (
  condition: FormattedDesiredCondition | null,
  jobCategories: JobType[]
): Selection[] => {
  const contents: Selection[] = [];
  if (condition !== null) {
    const jobTypes: string[] = Object.values(condition.jobs);
    const category = {
      major: 2,
      subCategory: 4,
      minor: 6,
    };

    /**
     * 条件を満たしているかどうかを判断して配列に追加する
     *
     * @param derivedJob 振り分け後の選択結果
     */
    const handleOccupation = (derivedJob: Selection | null): void => {
      if (derivedJob !== null) {
        contents.push(derivedJob);
      }
    };

    jobTypes.forEach((jobType) => {
      if (jobType.length === category.major) {
        handleOccupation(derivationMajorJob(jobType, jobCategories));
      }

      if (jobType.length === category.subCategory) {
        handleOccupation(derivationSubCategoryJob(jobType, jobCategories));
      }

      if (jobType.length === category.minor) {
        handleOccupation(derivationMinorJob(jobType, jobCategories));
      }
    });
  }
  return contents;
};

/**
 * cookieから取得した値を基に職種大分類コードの導出結果を提供する
 *
 * @param majorJobType 職種大分類コード
 * @param jobTypes 職種
 * @returns 職種大分類コード
 */
const derivationMajorJob = (
  majorJobType: string,
  jobTypes: JobType[]
): Selection | null => {
  const contents = jobTypes
    .filter((major) => majorJobType.includes(major.id))
    .map((major) => {
      return {
        name: major.header,
        value: major.id,
      };
    });
  return contents.length > 0 ? contents[0] : null;
};

/**
 * cookieから取得した値を基に職種中分類コードの導出結果を提供する
 *
 * @param subCategoryJobType 職種中分類コード
 * @param jobTypes 職種
 * @returns 職種中分類コード
 */
const derivationSubCategoryJob = (
  subCategoryJobType: string,
  jobTypes: JobType[]
): Selection | null => {
  const contents = jobTypes
    .flatMap((major) => major.subCategories)
    .filter((subCategory) => subCategoryJobType.includes(subCategory.id))
    .map((subCategory) => {
      return {
        name: subCategory.header,
        value: subCategory.id,
      };
    });
  return contents.length > 0 ? contents[0] : null;
};

/**
 * cookieから取得した値を基に職種小分類コードの導出結果を提供する
 *
 * @param minorJobType 職種小分類コード
 * @param jobTypes 職種
 * @returns 職種小分類コード
 */
const derivationMinorJob = (
  minorJobType: string,
  jobTypes: JobType[]
): Selection | null => {
  const contents = jobTypes
    .flatMap((major) =>
      major.subCategories.flatMap((subCategory) => subCategory.minors)
    )
    .filter((minor) => minorJobType.includes(minor.id))
    .map((minor) => {
      return {
        name: minor.name,
        value: minor.id,
      };
    });
  return contents.length > 0 ? contents[0] : null;
};

/**
 * cookieから取得した値を基に選択した希望勤務地の導出結果を提供する
 *
 * @param condition 希望条件
 * @param workplaces 勤務地候補
 * @returns 選択項目
 */
export const pickUpAreas = (
  condition: FormattedDesiredCondition | null,
  workplaces: Workplace[]
): Selection[] => {
  if (condition !== null) {
    const conditions: string[] = Object.values(condition.areas);
    // 逆引き用に都道府県と地域に分解する
    const areas: Selection[] = [];
    const places: Selection[] = [];
    workplaces.forEach((workplace) => {
      areas.push({
        name: workplace.header,
        value: workplace.value,
      });
      workplace.places.forEach((place) => {
        places.push({
          name: place.name,
          value: place.value,
        });
      });
    });

    const selections: Selection[] = [];
    conditions.forEach((c) => {
      areas.forEach((area) => {
        if (c === area.value) {
          selections.push(area);
        }
      });
      places.forEach((place) => {
        if (c === place.value) {
          selections.push(place);
        }
      });
    });
    return selections;
  }
  // 取得できない場合は初期値を提供する
  return [];
};

/**
 * cookieから取得した値を基に選択した希望年収の導出結果を提供する
 *
 * @param condition 希望条件
 * @param salaries 年収候補
 * @returns 選択項目
 */
export const pickUpIncome = (
  condition: FormattedDesiredCondition | null,
  salaries: Income[]
): Selection => {
  if (condition !== null) {
    const selectedIncome = salaries.find(
      (income) => income.value === condition.income
    );

    if (selectedIncome) {
      return {
        name: selectedIncome.name,
        value: selectedIncome.value,
      };
    }
  }
  // 取得できない場合は初期値を提供する
  return {
    name: '選択してください',
    value: '',
  };
};

/**
 * cookieへ書き込むJsonオブジェクトを提供する
 *
 * @param occupations 希望職種
 * @param locations 希望勤務地
 * @param income 希望年収
 * @param update 更新日
 * @returns cookieへ書き込むJsonオブジェクト
 */
export const convertSelectionToCookie = (
  occupations: Selection[],
  locations: Selection[],
  income: Selection
): DesiredCondition => {
  return {
    // 希望職種をdesired_condのプロパティへ変換する
    ...Object.assign({}, ...generatePropety(occupations, `job`, 3)),
    // 希望勤務地をdesired_condのプロパティへ変換する
    ...Object.assign({}, ...generatePropety(locations, `area`, 5)),
    income: income.value,
    update: new Date().getTime().toString(),
  };
};

const generatePropety = (
  selections: Selection[],
  name: string,
  max: number
) => {
  const items: { [x: string]: string }[] = [];
  const count = selections.length;
  // 値が存在する文だけ回す
  for (let i = 0; i < count; i += 1) {
    items.push({
      [`${name}${i + 1}`]: selections[i].value,
    });
  }

  // 値がない空のプロパティを生成する
  // 値が存在するのは不定
  // 値がある個数を最大から引いて残りは空のプロパティを生成する
  const limit = max - count;
  for (let i = 0; i < limit; i += 1) {
    items.push({ [`${name}${items.length + 1}`]: '' });
  }
  return items;
};

/**
 * アプリケーションで利用する形に整形した希望条件をcookieへの書き込む形へ変換した結果を提供する
 *
 * @param formattedDesiredCondition アプリケーションで利用する形に整形した希望条件
 * @returns cookieへの書き込む形へ変換した結果
 */
export const convertToDesiredCondition = (
  formattedDesiredCondition: FormattedDesiredCondition
) => {
  const desiredCondition: DesiredCondition = {
    area1: formattedDesiredCondition.areas[1],
    area2: formattedDesiredCondition.areas[2],
    area3: formattedDesiredCondition.areas[3],
    area4: formattedDesiredCondition.areas[4],
    area5: formattedDesiredCondition.areas[5],
    job1: formattedDesiredCondition.jobs[1],
    job2: formattedDesiredCondition.jobs[2],
    job3: formattedDesiredCondition.jobs[3],
    income: formattedDesiredCondition.income,
    update: formattedDesiredCondition.update,
  };
  return desiredCondition;
};

/**
 * チェックボックス更新時、選択項目が既に含まれているかどうかの判定結果を提供する
 *
 * @param items 選択中の項目
 * @param value 値
 * @returns true:含まれている, false:含まれていない
 */
export const isSelectedItem = (items: Selection[], value: string) => {
  return items.some((item) => item.value === value);
};
