import { Button, Text } from '@doda/io-react/common';
import { MouseEventHandler } from 'react';
import styles from './index.module.scss';

/**
 * BottomButtonコンポーネントのプロパティ
 */
interface Props {
  /**
   * クリックイベント
   */
  onClick: MouseEventHandler<HTMLButtonElement>;
  /**
   * 戻るボタンへ付与するクリックイベント
   */
  onClickForBack?: MouseEventHandler<HTMLButtonElement> | undefined;
  /**
   * 現在の選択数
   */
  current: number;
  /**
   * 選択できる最大数
   */
  limit: number;
  /**
   * 初回のヒアリング状態で起動するかどうか
   *
   * 初回のヒアリング状態にするならtrueを指定する
   */
  startupMode: boolean;
}

/**
 * 下部ボタン
 *
 * @param {Props} props 構築情報
 */
export const BottomButton: React.FC<Props> = ({
  onClick,
  onClickForBack,
  current,
  limit,
  startupMode,
}: Props) => {
  return (
    <div className={styles.bottomButton}>
      {onClickForBack !== undefined && startupMode && (
        <Button
          size="M"
          as="button"
          variant="outline_gray"
          onClick={onClickForBack}
        >
          戻る
        </Button>
      )}

      {current <= limit ? (
        <Button
          size="M"
          variant="green"
          as="button"
          onClick={onClick}
          className={styles.bottomButton__item}
        >
          {startupMode ? '次へ' : '設定する'}
        </Button>
      ) : (
        <Button
          size="M"
          as="button"
          onClick={() => {}}
          className={`${styles.bottomButton__item} ${
            styles[`bottomButton__item--error`]
          }`}
        >
          <Text color="white" as="span" size="16">
            設定数が上限を超えました
          </Text>
          <Text color="white" as="span" size="10">
            最大{limit}件まで設定できます
          </Text>
        </Button>
      )}
    </div>
  );
};
