import { JobTypeLResponse } from '@doda/api-client/aspida/@types';
import { AreaListOfSearchModal } from '@doda/common/src/models/MasterCacheModel';
import { JobType, Workplace } from '../../../type';

/**
 * 加工した地域・都道府県データを提供する
 *
 * @returns 加工した地域・都道府県データ
 */
export const transformAreas = (areas: AreaListOfSearchModal[]): Workplace[] => {
  return areas.map((area) => {
    return {
      header: area.name,
      value: `AR${area.id}`,
      places: area.prefectureList.map((prefecture) => ({
        name: prefecture.name,
        value: `PR${prefecture.id}`,
      })),
    };
  });
};

/**
 * 加工した職種データを提供する
 *
 * @returns 加工した職種マスタ
 */
export const transformJobTypes = (jobs: JobTypeLResponse[]): JobType[] => {
  /**
   * 任意の並び順を指定している
   *
   * key:value
   *
   * key ... 職種大分類ID, id ... 並び順
   */
  const order = {
    '01': 1,
    '02': 2,
    '03': 3,
    '04': 4,
    '14': 5,
    '05': 6,
    '06': 7,
    '07': 8,
    '15': 9,
    '08': 10,
    '09': 11,
    '10': 12,
    '11': 13,
    '12': 14,
    '13': 15,
  };

  return jobs
    .map((job) => {
      return {
        id: job.id,
        searchJobTypeId: job.searchJobTypeId,
        header: job.name,
        subCategories: job.jobTypeMList.map((subCategory) => ({
          id: subCategory.id,
          searchJobTypeId: subCategory.searchJobTypeId,
          header: subCategory.name,
          minors: subCategory.jobTypeSList.map((minor) => ({
            id: minor.id,
            searchJobTypeId: minor.searchJobTypeId,
            name: minor.name,
          })),
        })),
      };
    })
    .sort((first: JobType, second: JobType) => {
      const primary = order[first.id];
      const secondary = order[second.id];
      return primary - secondary;
    });
};
