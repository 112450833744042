import { MouseEventHandler } from 'react';
import { Button } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * BackButtonコンポーネントのプロパティ
 */
interface Props {
  /** クリックイベントのハンドラー */
  onClick: MouseEventHandler<HTMLButtonElement>;
}

/**
 * 戻るボタンコンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @returns {JSX.Element} BackButtonのJSX要素
 */
export const BackButton: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <Button
      size="XS"
      variant="outline_gray"
      as="button"
      onClick={onClick}
      className={styles.backButton__item}
    >
      希望条件の設定へ戻る
    </Button>
  );
};
