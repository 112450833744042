import { OutlineTag, Text } from '@doda/io-react/common';
import styles from './index.module.scss';

/** ItemコンポーネントのProps */
interface Props {
  /**
   * 項目名
   */
  name: string;
  /**
   * 現在の選択数
   */
  current: number;
  /**
   * 選択できる最大数
   */
  limit: number;
}

/**
 * 選択項目
 *
 * @param props 構築情報
 */
export const Item: React.FC<Props> = ({ name, current, limit }) => {
  return (
    <div className={styles.item}>
      <Text color="black_pc" as="span" size="14--articlePC">
        {name}
      </Text>
      {current > 0 && current <= limit && (
        <OutlineTag color="lightGreen" className={styles.item__tag}>
          第{current}希望
        </OutlineTag>
      )}
    </div>
  );
};
