import { RefObject } from 'react';
import { Button } from '@doda/io-react/common';
import { useAppDispatch } from '@doda/common/src/redux/hooks';
import { useSelector } from 'react-redux';
import { adobeAnalyticsScriptsSelector } from '@doda/common/src/redux/features/analytics';
import { useMatchJobResult } from '@doda/common/src/components/DesiredConditionsModal/hooks/useMatchJobResult';
import {
  handleSubmitResultSubmit,
  handleShowModal,
  handleCancel,
} from '@doda/common/src/components/DesiredConditionsModal/logics/event';
import { displayName } from '@doda/common/src/components/DesiredConditionsModal/logics/function';
import { CountContext } from '@doda/common/src/components/DesiredConditionsModal/logics/context';
import { useUpdateStateFromCookie } from '@doda/common/src/components/DesiredConditionsModal/logics/hook';
import { measureOnClickChange } from '@doda/common/src/components/DesiredConditionsModal/logics/function/analytics';
import {
  Income as Salary,
  Workplace,
  JobType,
} from '@doda/common/src/components/DesiredConditionsModal/type';
import { SelectedItemsDisplay } from '../SelectedItemsDisplay';
import { Count } from '../Count';
import { Occupation } from '../Occupation';
import { Location } from '../Location';
import { Income } from '../Income';
import styles from '../../index.module.scss';
import { CancelButton } from '../CancelButton';
import { IncomeStartupMode } from '../IncomeStartupMode';

/**
 * 検索結果ダイアログのProps
 */
interface Props {
  /**
   * Resultのdialog要素の参照情報
   */
  dialogReference: RefObject<HTMLDialogElement>;
  /**
   * 加工した職種データ
   */
  jobCategories: JobType[];
  /**
   * 加工した地域/都道府県データ
   */
  workplaces: Workplace[];
  /**
   * 年収下限
   */
  salaries: Salary[];
  /**
   * 初回のヒアリング状態で起動するかどうか
   *
   * 初回のヒアリング状態にするならtrueを指定する
   */
  startupMode: boolean;
}

/**
 * 検索結果ダイアログ
 *
 * @param {Props} props 各ダイアログのリストへ展開するデータ群
 */
export const Result: React.FC<Props> = ({
  dialogReference,
  jobCategories,
  workplaces,
  salaries,
  startupMode,
}: Props) => {
  const {
    jobDialogReference,
    workplaceDialogReference,
    salaryDialogReference,
    jobCount,
  } = useMatchJobResult();
  const { updateStateFromCookie, occupations, locations, salary } =
    useUpdateStateFromCookie(jobCategories, workplaces, salaries);

  const dispatch = useAppDispatch();
  const hasAdobeAnalyticsScripts = useSelector(adobeAnalyticsScriptsSelector);

  const incomeProps = {
    selefDialogReference: salaryDialogReference,
    controlledDialogReference: dialogReference,
    salaries,
    startupMode,
  };

  return (
    <dialog id="result" ref={dialogReference} className={styles.dialog}>
      <div className={styles.dialog__header}>
        <CountContext.Provider value={jobCount}>
          <Count
            jobCategories={jobCategories}
            prepare={updateStateFromCookie}
          />
        </CountContext.Provider>
        <div className={styles.dialog__header__right}>
          <CancelButton
            onClick={() => handleCancel(() => dialogReference.current?.close())}
          />
        </div>
      </div>

      <div className={styles.dialog__layout}>
        <div className={styles.dialog__content}>
          <SelectedItemsDisplay
            heading="希望職種"
            labels={displayName(occupations)}
            customStyle={styles[`dialog__box--top`]}
            onClick={() => handleShowModal(jobDialogReference)}
            displayLabel
          />
          <Occupation
            selefDialogReference={jobDialogReference}
            controlledDialogReference={dialogReference}
            jobTypes={jobCategories}
            limit={3}
            startupMode={startupMode}
          />

          <SelectedItemsDisplay
            heading="希望勤務地"
            labels={displayName(locations)}
            customStyle={styles[`dialog__box--middle`]}
            onClick={() => handleShowModal(workplaceDialogReference)}
            displayLabel
          />
          <Location
            selefDialogReference={workplaceDialogReference}
            controlledDialogReference={dialogReference}
            workplaces={workplaces}
            limit={5}
            startupMode={startupMode}
          />

          <SelectedItemsDisplay
            heading="希望年収"
            labels={salary.name ? [salary.name] : []}
            customStyle={styles[`dialog__box--bottom`]}
            onClick={() => handleShowModal(salaryDialogReference)}
            displayLabel={false}
          />
          {startupMode ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <IncomeStartupMode {...incomeProps} />
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Income {...incomeProps} />
          )}
        </div>
      </div>

      <div className={styles.dialog__bottom}>
        <Button
          size="M"
          variant="green"
          as="button"
          className={styles.dialog__bottom__item}
          onClick={() => {
            handleSubmitResultSubmit(
              dialogReference,
              occupations,
              locations,
              salary
            );
            measureOnClickChange(hasAdobeAnalyticsScripts, dispatch);
          }}
        >
          希望条件を変更する
        </Button>
      </div>
    </dialog>
  );
};

export default Result;
