import { Income } from '@doda/common/src/components/DesiredConditionsModal/type';

/**
 * 希望条件変更モーダルで利用する希望の年収
 *
 * 計算処理に用いず、Cookieから読み込んだ値と比較するため文字列で定義している
 */
export const contents: Income[] = [
  {
    name: 'まだ決まっていない',
    value: '',
  },

  {
    name: '200万円～',
    value: '200',
  },
  {
    name: '250万円～',
    value: '250',
  },
  {
    name: '300万円～',
    value: '300',
  },
  {
    name: '350万円～',
    value: '350',
  },
  {
    name: '400万円～',
    value: '400',
  },
  {
    name: '450万円～',
    value: '450',
  },
  {
    name: '500万円～',
    value: '500',
  },
  {
    name: '550万円～',
    value: '550',
  },
  {
    name: '600万円～',
    value: '600',
  },
  {
    name: '650万円～',
    value: '650',
  },
  {
    name: '700万円～',
    value: '700',
  },
  {
    name: '750万円～',
    value: '750',
  },
  {
    name: '800万円～',
    value: '800',
  },

  {
    name: '850万円～',
    value: '850',
  },
  {
    name: '900万円～',
    value: '900',
  },
  {
    name: '950万円～',
    value: '950',
  },
  {
    name: '1000万円～',
    value: '1000',
  },
];
