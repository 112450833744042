import {
  JobCountResponse,
  LowerLimitIncomeRequestCode,
} from '@doda/api-client/aspida/@types';
import { apiClient } from '@doda/api-client/util/apiClient';
import { appAxios } from '@doda/api-client/libs/axios/appAxios';
import { DesiredCondition, Query } from '../../../type';

/**
 * 検索条件に紐づいた求人件数を提供する
 *
 * APIの呼び出しに異常が発生した場合は、0を提供する
 *
 * @param majorJobs 職種大分類コード
 * @param subCategoryJobs 職種中分類コード
 * @param minorJobs 職種小分類コード
 * @param areas 地域コード
 * @param prefectures 都道府県コード
 * @param income 年収下限
 * @returns 件数
 */
export const fetchJobCount = async (
  majorJobs?: string[],
  subCategoryJobs?: string[],
  minorJobs?: string[],
  areas?: string[],
  prefectures?: string[],
  income?: LowerLimitIncomeRequestCode
) => {
  const query: Query = {
    searchJobTypeLId: majorJobs,
    searchJobTypeMId: subCategoryJobs,
    searchJobTypeSId: minorJobs,
    areaId: areas,
    prefectureId: prefectures,
    lowerLimitIncome: income,
  };

  try {
    const response: JobCountResponse = await apiClient.api.v1.jobs.count.$get({
      query,
    });
    return response.totalCount ?? 0;
  } catch (error) {
    return 0;
  }
};

/**
 * 選択結果を希望条件登録APIへ送信する
 *
 * @param desiredCondition 希望条件
 */
export const postDesiredConditionCookie = async (
  desiredCondition: DesiredCondition
) => {
  const form = new FormData();
  form.append(`area1`, desiredCondition.area1);
  form.append(`area2`, desiredCondition.area2);
  form.append(`area3`, desiredCondition.area3);
  form.append(`area4`, desiredCondition.area4);
  form.append(`area5`, desiredCondition.area5);
  form.append(`job1`, desiredCondition.job1);
  form.append(`job2`, desiredCondition.job2);
  form.append(`job3`, desiredCondition.job3);
  form.append(`income`, desiredCondition.income);
  form.append(`update`, desiredCondition.update);

  await appAxios({
    url: `/dcfront/cms/desiredConditionRegister/`,
    method: `post`,
    data: form,
  });
};

/**
 * 希望条件同期APIを呼び出す
 */
export const getDesiredConditionSynchronize = (): void => {
  appAxios({
    url: `/dcfront/cms/desiredConditionSync/`,
    method: `get`,
  });
};
