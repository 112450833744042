import { RefObject, useContext, useEffect } from 'react';
import {
  AccordionList,
  MiddleAccordionCheckListItem,
  TopAccordionListItem,
  MiddleAccordionListItem,
  BottomAccordionCheckListItem,
} from '@doda/io-react/common';
import { useAppDispatch } from '@doda/common/src/redux/hooks';
import { useSelector } from 'react-redux';
import { adobeAnalyticsScriptsSelector } from '@doda/common/src/redux/features/analytics';
import {
  useEscapeClose,
  useSelection,
} from '@doda/common/src/components/DesiredConditionsModal/logics/hook';
import {
  handleCancel,
  handleSubmitOccupations,
} from '@doda/common/src/components/DesiredConditionsModal/logics/event';
import {
  numericSelection,
  isSelectedItem,
} from '@doda/common/src/components/DesiredConditionsModal/logics/function';
import {
  handleIsSelectedHeader,
  handleIsSelectedItem,
} from '@doda/common/src/components/DesiredConditionsModal/logics/occupation';
import { OccupationContext } from '@doda/common/src/components/DesiredConditionsModal/logics/context';
import { measureOnClickOccupation } from '@doda/common/src/components/DesiredConditionsModal/logics/function/analytics';
import { JobType } from '@doda/common/src/components/DesiredConditionsModal/type';
import styles from '../../index.module.scss';
import { BottomButton } from '../BottomButton';
import { Item } from '../Item';
import { CancelButton } from '../CancelButton';
import { Title } from '../Title';
import { BackButton } from '../BackButton';

/**  職種選択ダイアログのProps */
interface Props {
  /**
   * ダイアログ参照情報
   */
  selefDialogReference: RefObject<HTMLDialogElement>;
  /**
   * 制御するダイアログ参照情報
   */
  controlledDialogReference: RefObject<HTMLDialogElement>;
  /**
   * 前のダイアログ参照情報
   */
  backedDialogReference?: RefObject<HTMLDialogElement>;
  /**
   * 加工した職種データ
   */
  jobTypes: JobType[];
  /**
   * 最大選択数
   */
  limit: number;
  /**
   * 初回のヒアリング状態で起動するかどうか
   *
   * 初回のヒアリング状態にするならtrueを指定する
   */
  startupMode: boolean;
}

/**
 * 職種選択ダイアログ
 *
 * @param {Props} props 構築情報
 */
export const Occupation: React.FC<Props> = ({
  selefDialogReference,
  controlledDialogReference,
  backedDialogReference,
  jobTypes,
  limit,
  startupMode,
}: Props) => {
  const { selectedItems, toggleSelection, setItems } = useSelection([]);
  const [occupations, setOccupations] = useContext(OccupationContext);
  const dispatch = useAppDispatch();
  const hasAdobeAnalyticsScripts = useSelector(adobeAnalyticsScriptsSelector);
  useEscapeClose(selefDialogReference, controlledDialogReference);

  useEffect(() => {
    setItems(occupations);
  }, [occupations, setItems]);

  return (
    <dialog
      id="occupation"
      ref={selefDialogReference}
      className={styles.dialog}
    >
      <div className={styles.dialog__header}>
        <Title type="希望の職種" limit={limit} />
        <div className={styles.dialog__header__right}>
          <CancelButton
            onClick={() => {
              handleCancel(
                () => selefDialogReference.current?.close(),
                () => controlledDialogReference.current?.close()
              );
            }}
          />
        </div>
      </div>

      <div
        className={`${styles.dialog__layout} ${
          styles[`selection-dialog__layout`]
        }`}
      >
        {!startupMode && (
          <div className={styles[`selection-dialog__content-upper`]}>
            <BackButton
              onClick={() => {
                selefDialogReference.current?.close();
              }}
            />
          </div>
        )}

        <AccordionList
          boxShadow="blue"
          className={`${styles.dialog__content} ${
            styles[`selection-dialog__content`]
          }`}
        >
          {jobTypes.map((jobType) => {
            return (
              <TopAccordionListItem
                heading={jobType.header}
                key={jobType.id}
                selected={handleIsSelectedHeader(selectedItems, jobType)}
              >
                <MiddleAccordionCheckListItem
                  heading={
                    <Item
                      name={`${jobType.header}すべて`}
                      current={numericSelection(selectedItems, jobType.id)}
                      limit={limit}
                    />
                  }
                  value=""
                  checked={isSelectedItem(selectedItems, jobType.id)}
                  onChange={() =>
                    toggleSelection({
                      name: jobType.header,
                      value: jobType.id,
                    })
                  }
                  className={
                    numericSelection(selectedItems, jobType.id) > limit
                      ? styles[`selection-dialog__content__item--error`]
                      : styles[`selection-dialog__content__item`]
                  }
                />

                {jobType.subCategories.map((subCategory) => {
                  return (
                    <MiddleAccordionListItem
                      heading={subCategory.header}
                      selected={handleIsSelectedItem(
                        selectedItems,
                        subCategory
                      )}
                      key={subCategory.searchJobTypeId}
                    >
                      <BottomAccordionCheckListItem
                        heading={
                          <Item
                            name={`${subCategory.header}すべて`}
                            current={numericSelection(
                              selectedItems,
                              subCategory.id
                            )}
                            limit={limit}
                          />
                        }
                        value=""
                        checked={isSelectedItem(selectedItems, subCategory.id)}
                        onChange={() =>
                          toggleSelection({
                            name: subCategory.header,
                            value: subCategory.id,
                          })
                        }
                        className={
                          numericSelection(selectedItems, subCategory.id) >
                          limit
                            ? styles[`selection-dialog__content__item--error`]
                            : styles[`selection-dialog__content__item`]
                        }
                      />

                      {subCategory.minors.map((minor) => {
                        return (
                          <BottomAccordionCheckListItem
                            key={minor.id}
                            heading={
                              <Item
                                name={minor.name}
                                current={numericSelection(
                                  selectedItems,
                                  minor.id
                                )}
                                limit={limit}
                              />
                            }
                            value=""
                            checked={isSelectedItem(selectedItems, minor.id)}
                            onChange={() =>
                              toggleSelection({
                                name: minor.name,
                                value: minor.id,
                              })
                            }
                            className={
                              numericSelection(selectedItems, minor.id) > limit
                                ? styles[
                                    `selection-dialog__content__item--error`
                                  ]
                                : styles[`selection-dialog__content__item`]
                            }
                          />
                        );
                      })}
                    </MiddleAccordionListItem>
                  );
                })}
              </TopAccordionListItem>
            );
          })}
        </AccordionList>
      </div>

      <BottomButton
        onClick={() => {
          if (startupMode) {
            controlledDialogReference.current?.showModal();
            measureOnClickOccupation(hasAdobeAnalyticsScripts, dispatch);
          }
          handleSubmitOccupations(
            () => selefDialogReference.current?.close(),
            selectedItems,
            setOccupations,
            startupMode
          );
        }}
        onClickForBack={() => {
          backedDialogReference?.current?.showModal();
          selefDialogReference.current?.close();
        }}
        current={selectedItems.length}
        limit={limit}
        startupMode={startupMode}
      />
    </dialog>
  );
};
