import { LowerLimitIncomeRequestCode } from '@doda/api-client/aspida/@types';
import { JobType, Selection } from '../../../type';

/**
 * 件数取得APIの職種大分類コード、職種中分類コード、職種小分類コードへのクエリパラメータ用の値へ変換した結果を提供する
 *
 * @param occupations 選択した職種
 * @param jobTypes 職種マスタの実行結果
 * @returns `{ majorOccupations: 職種大分類コード
 *             , subCategoryOccupations: 職種中分類コード
 *             , minorOccupations: 職種小分類コード
 *          }`
 */
export const convertToJobTypeSelection = (
  occupations: Selection[],
  jobTypes: JobType[]
) => {
  const category = {
    /**
     * 大分類
     */
    major: 2,
    /**
     * 中分類
     */
    subCategory: 4,
    /**
     * 小分類
     */
    minor: 6,
  };
  return {
    majorJobs: derivationMajorSearchJobTypeIds(
      occupations
        .filter((major) => major && major.value.length === category.major)
        .map((item) => {
          return item.value;
        }),
      jobTypes
    ),
    subCategoryJobs: derivationSubCategorySearchJobTypeIds(
      occupations
        .filter(
          (subCategory) =>
            subCategory && subCategory.value.length === category.subCategory
        )
        .map((item) => {
          return item.value;
        }),
      jobTypes
    ),
    minorJobs: derivationMinorSearchJobTypeIds(
      occupations
        .filter((minor) => minor && minor.value.length === category.minor)
        .map((item) => {
          return item.value;
        }),
      jobTypes
    ),
  };
};

/**
 * 検索用の職種大分類コードの導出結果を提供する
 *
 * @param majorJobTypes 職種大分類コード
 * @param jobTypes 職種
 * @returns 検索用の職種大分類コード
 */
const derivationMajorSearchJobTypeIds = (
  majorJobTypes: string[],
  jobTypes: JobType[]
): string[] => {
  return jobTypes
    .filter((major) => majorJobTypes.includes(major.id))
    .map((major) => major.searchJobTypeId);
};

/**
 * 検索用の職種中分類コードの導出結果を提供する
 *
 * @param subCategoryJobTypes 職種中分類コード
 * @param jobTypes 職種
 * @returns 検索用の職種中分類コード
 */
const derivationSubCategorySearchJobTypeIds = (
  subCategoryJobTypes: string[],
  jobTypes: JobType[]
): string[] => {
  return jobTypes
    .flatMap((major) => major.subCategories)
    .filter((subCategory) => subCategoryJobTypes.includes(subCategory.id))
    .map((subCategory) => subCategory.searchJobTypeId);
};

/**
 * 検索用の職種小分類コードの導出結果を提供する
 *
 * @param minorJobTypes 職種小分類コード
 * @param jobTypes 職種
 * @returns 検索用の職種小分類コード
 */
const derivationMinorSearchJobTypeIds = (
  minorJobTypes: string[],
  jobTypes: JobType[]
): string[] => {
  return jobTypes
    .flatMap((major) =>
      major.subCategories.flatMap((subCategory) => subCategory.minors)
    )
    .filter((minor) => minorJobTypes.includes(minor.id))
    .map((minor) => minor.searchJobTypeId);
};

/**
 * 件数取得APIの地域・都道府県用の値へ変換した結果を提供する
 *
 * @param locations 選択した勤務地
 * @returns 地域・都道府県
 */
export const convertToAreaSelection = (locations: Selection[]) => {
  const areas = locations
    .filter((location) => location && location.value.includes('AR'))
    .map((item) => item.value.slice(2));
  const prefectures = locations
    .filter((location) => location && location.value.includes('PR'))
    .map((item) => item.value.slice(2));
  return { areas, prefectures };
};

/**
 * 件数取得APIの年収下限用の値へ変換した結果を提供する
 *
 * @param salary 選択した希望年収
 * @returns 年収下限
 */
export const convertToIncomeSelection = (salary: Selection) => {
  const code = {
    '200': '1',
    '250': '2',
    '300': '3',
    '350': '4',
    '400': '5',
    '450': '6',
    '500': '7',
    '550': '8',
    '600': '9',
    '650': '10',
    '700': '11',
    '750': '11',
    '800': '12',
    '850': '12',
    '900': '13',
    '950': '13',
    '1000': '14',
  };
  const income: LowerLimitIncomeRequestCode = code[salary.value];
  return { income };
};
