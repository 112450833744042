import { JobType, Selection } from '../../../type';
import {
  convertToAreaSelection,
  convertToIncomeSelection,
  convertToJobTypeSelection,
} from '../convert';
import { fetchJobCount } from '../api';

/**
 * 選択項目の結果を元に求人件数の検索条件を付与した結果を提供する
 *
 * 処理の中で検索用職種コードへ変換する際に、逆引きするため
 *
 * jobCategoriesを要求している
 *
 * @param occupations 選択した職種
 * @param locations 選択した候補地
 * @param salary 選択した希望年収
 * @param jobCategories 加工した職種データ
 * @returns 件数
 */
export const jobCount = async (
  occupations: Selection[],
  locations: Selection[],
  salary: Selection,
  jobCategories: JobType[]
) => {
  const { majorJobs, subCategoryJobs, minorJobs } = convertToJobTypeSelection(
    occupations,
    jobCategories
  );
  const { areas, prefectures } = convertToAreaSelection(locations);
  const { income } = convertToIncomeSelection(salary);

  return fetchJobCount(
    majorJobs.length ? majorJobs : undefined,
    subCategoryJobs.length ? subCategoryJobs : undefined,
    minorJobs.length ? minorJobs : undefined,
    areas.length ? areas : undefined,
    prefectures.length ? prefectures : undefined,
    income
  );
};
