import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react';

/**
 * 希望条件モーダルResultコンポーネントのState管理を行う
 */
export const useMatchJobResult = () => {
  const jobDialogReference = useRef<HTMLDialogElement>(null);
  const workplaceDialogReference = useRef<HTMLDialogElement>(null);
  const salaryDialogReference = useRef<HTMLDialogElement>(null);
  const [count, setCount] = useState<number>(0);
  const jobCount: [number, Dispatch<SetStateAction<number>>] = useMemo(
    () => [count, setCount],
    [count]
  );
  return {
    jobDialogReference,
    workplaceDialogReference,
    salaryDialogReference,
    jobCount,
  };
};
