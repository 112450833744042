import { RefObject } from 'react';
import { DesiredCondition, Selection } from '../type';
import { convertSelectionToCookie } from './function';
import {
  setCookie,
  updateIncomeCookie,
  updateLocationCookie,
  updateOccupationCookie,
} from './function/cookie';

/**
 * ダイアログの起動イベントを提供する
 *
 * @param dialog 対象のダイアログ
 */
export const handleShowModal = (dialog: RefObject<HTMLDialogElement>) => {
  dialog.current?.showModal();
};

/**
 * 呼び出し元のダイアログがあれば合わせて閉じる
 *
 * 職種、勤務地、希望の年収のダイアログに「閉じるアイコン」がある。
 *
 * 「閉じるアイコン」をクリック時、希望条件変更ダイアログも合わせて閉じる設計のため用意した関数
 *
 * @param currentDialogEvent 現在のダイアログ参照情報
 * @param dialogEvent 呼び出し元のダイアログ参照情報
 */
export const handleCancel = (
  currentDialogEvent: () => void,
  dialogEvent?: () => void
) => {
  document.body.classList.remove('scroll-lock');
  currentDialogEvent();
  if (dialogEvent !== undefined) {
    dialogEvent();
  }
};

/**
 * 選択した希望職種の決定イベント
 *
 * @param dialogEvent ダイアログイベント
 * @param items 選択項目
 * @param onSelection 更新処理
 * @param startupMode 初回ヒアリング状態
 */
export const handleSubmitOccupations = (
  dialogEvent: () => void,
  items: Selection[],
  onSelection: (selection: Selection[]) => void,
  startupMode: boolean | undefined
) => {
  let isStartup = false;
  if (startupMode === undefined) {
    isStartup = false;
  } else {
    isStartup = startupMode;
  }
  submitEvent(items, onSelection, dialogEvent);
  updateOccupationCookie('desired_cond', items, isStartup);
};

/**
 * 選択した希望勤務地の決定イベント
 *
 * @param dialogEvent ダイアログイベント
 * @param items 選択項目
 * @param onSelection 更新処理
 * @param startupMode 初回のヒアリング状態にするならtrueを指定する
 */
export const handleSubmitLocations = (
  dialogEvent: () => void,
  items: Selection[],
  onSelection: (selection: Selection[]) => void,
  startupMode: boolean
) => {
  submitEvent(items, onSelection, dialogEvent);
  updateLocationCookie('desired_cond', items, startupMode);
};

const submitEvent = (
  items: Selection[],
  onSelection: (selection: Selection[]) => void,
  dialogEvent: () => void
) => {
  onSelection(items);
  dialogEvent();
};

/**
 * 選択した希望年収の決定イベント
 *
 * @param dialogEvent ダイアログイベント
 * @param selectedItem 選択項目
 * @param onSelection 更新処理
 * @param startupMode 初回ヒアリング状態
 */
export const handleSubmitIncome = async (
  dialogEvent: () => void,
  selectedItem: Selection,
  onSelection: (selection: Selection) => void,
  startupMode: boolean | undefined
) => {
  let isStartup = false;
  if (startupMode === undefined) {
    isStartup = false;
  } else {
    isStartup = startupMode;
  }
  onSelection(selectedItem);
  await updateIncomeCookie('desired_cond', selectedItem, isStartup);
  dialogEvent();
};

export const SUBMIT = 'SUBMIT';

/**
 * 選択した検索結果の決定イベント
 *
 * @param dialogReference ダイアログイベント
 * @param occupations 希望職種
 * @param locations 希望勤務地
 * @param salary 希望年収
 */
export const handleSubmitResultSubmit = async (
  dialogReference: RefObject<HTMLDialogElement>,
  occupations: Selection[],
  locations: Selection[],
  salary: Selection
) => {
  document.body.classList.remove('scroll-lock');
  dialogReference.current?.close(SUBMIT);
  const content: DesiredCondition = convertSelectionToCookie(
    occupations,
    locations,
    salary
  );
  setCookie(content);
};
