import { MouseEventHandler } from 'react';
import { Close } from '@doda/io-react/common';
import styles from './index.module.scss';

/**
 * CancelButtonコンポーネントのプロパティ
 */
interface Props {
  /** クリックイベントのハンドラー（または未定義） */
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
}

/**
 * キャンセルボタンコンポーネント
 * @param {Props} props - コンポーネントのプロパティ
 * @returns {JSX.Element} CancelButtonのJSX要素
 */
export const CancelButton: React.FC<Props> = ({ onClick }: Props) => {
  return (
    <button type="button" onClick={onClick} className={styles.button}>
      <Close color="black" size="M" />
    </button>
  );
};
