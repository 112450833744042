import { Selection, Workplace } from '../type';

/**
 * 地域名の見出しに「選択中」を表示するかの判断結果を提供する
 *
 * 以下の条件の場合に「選択中」の文言を表示させる
 *
 * (1) {地域名}すべてを選択した場合
 *
 * (2) {都道府県}を選択した場合
 *
 * @param items 選択中の項目群
 * @param contents 地域/都道府県
 * @returns true:表示, false:非表示
 */
export const handleIsSelectedItem = (
  items: Selection[],
  content: Workplace
): boolean => {
  if (items.length === 0) {
    return false;
  }

  // 地域名が含まれているか検証
  if (items.some((item) => content.value === item.value)) {
    return true;
  }

  // 都道府県群を生成して含まれているか検証
  const prefectures: string[] = content.places.map((item) => item.value);
  return items.some(
    (item) => item.value === content.value || prefectures.includes(item.value)
  );
};
