import { RefObject, useContext, useEffect } from 'react';
import { Text, ArrowRight, Button } from '@doda/io-react/common';
import { useAppDispatch } from '@doda/common/src/redux/hooks';
import { useSelector } from 'react-redux';
import { adobeAnalyticsScriptsSelector } from '@doda/common/src/redux/features/analytics';
import {
  useChange,
  useEscapeClose,
} from '@doda/common/src/components/DesiredConditionsModal/logics/hook';
import {
  handleCancel,
  handleSubmitIncome,
} from '@doda/common/src/components/DesiredConditionsModal/logics/event';
import { SalaryContext } from '@doda/common/src/components/DesiredConditionsModal/logics/context';
import { measureOnClickIncome } from '@doda/common/src/components/DesiredConditionsModal/logics/function/analytics';
import { Income as Salaries } from '@doda/common/src/components/DesiredConditionsModal/type';
import styles from './index.module.scss';
import { CancelButton } from '../CancelButton';
import dialogStyles from '../../index.module.scss';

/**
 * 希望年収選択ダイアログのProps
 */
interface Props {
  /**
   * ダイアログ参照情報
   */
  selefDialogReference: RefObject<HTMLDialogElement>;
  /**
   * 制御するダイアログ参照情報
   */
  controlledDialogReference: RefObject<HTMLDialogElement>;
  /**
   * 前のダイアログ参照情報
   */
  backedDialogReference?: RefObject<HTMLDialogElement>;
  /**
   * 希望年収データ
   */
  salaries: Salaries[];
  /**
   * 初回のヒアリング状態で起動するかどうか
   *
   * 初回のヒアリング状態にするならtrueを指定する
   */
  startupMode: boolean;
}

/**
 * 希望年収選択ダイアログ
 *
 * @param {Props} props 構築情報
 */
export const IncomeStartupMode: React.FC<Props> = ({
  selefDialogReference,
  controlledDialogReference,
  backedDialogReference,
  salaries,
  startupMode,
}: Props) => {
  const { selectedItem, toggleSelection, setItem } = useChange({
    name: '選択してください',
    value: '',
  });
  const [salary, setSalary] = useContext(SalaryContext);
  const dispatch = useAppDispatch();
  const hasAdobeAnalyticsScripts = useSelector(adobeAnalyticsScriptsSelector);
  useEscapeClose(selefDialogReference, controlledDialogReference);

  useEffect(() => {
    setItem(salary);
  }, [salary, setItem]);

  return (
    <dialog
      id="income"
      ref={selefDialogReference}
      className={dialogStyles.dialog}
    >
      <div className={dialogStyles.dialog__header}>
        <Text
          color="black_pc"
          as="span"
          size="14--articlePC"
          className={styles[`income-dialog__header__text`]}
        >
          <Text
            color="black_pc"
            as="span"
            weight="bold"
            size="14--articlePC"
            className={styles[`income-dialog__header__middle-text`]}
          >
            希望の年収
          </Text>
          を教えてください
        </Text>

        <div className={dialogStyles.dialog__header__right}>
          <CancelButton
            onClick={() => {
              handleCancel(
                () => selefDialogReference.current?.close(),
                () => controlledDialogReference.current?.close()
              );
            }}
          />
        </div>
      </div>

      <div
        className={`${dialogStyles.dialog__layout} ${
          dialogStyles[`selection-dialog__layout`]
        }`}
      >
        <ul
          className={`${dialogStyles.dialog__content} ${
            dialogStyles[`selection-dialog__content`]
          }`}
        >
          {salaries.map((income, index) => {
            return (
              <li key={income.name}>
                <button
                  className={styles[`income-dialog__content-item`]}
                  type="button"
                  key={index}
                  onClick={() => {
                    handleSubmitIncome(
                      () => {
                        selefDialogReference.current?.close();
                        window.location.href =
                          '/DodaFront/View/MatchJobList.action';
                      },
                      toggleSelection({
                        name: income.name,
                        value: income.value,
                      }),
                      setSalary,
                      startupMode
                    );
                  }}
                >
                  <Text color="black_pc">{income.name}</Text>
                  <div className={styles[`income-dialog__content-item--right`]}>
                    <ArrowRight color="black" size="M" />
                  </div>
                </button>
              </li>
            );
          })}
        </ul>
      </div>

      <div className={dialogStyles.dialog__bottom}>
        {backedDialogReference !== undefined && (
          <Button
            size="M"
            as="button"
            variant="outline_gray"
            onClick={() => {
              backedDialogReference.current?.showModal();
              selefDialogReference.current?.close();
            }}
          >
            戻る
          </Button>
        )}
        <Button
          size="M"
          variant="green"
          as="a"
          href="/DodaFront/View/MatchJobList.action"
          onClick={() => {
            measureOnClickIncome(hasAdobeAnalyticsScripts, dispatch);
            setSalary(selectedItem);
          }}
          className={dialogStyles.dialog__bottom__item}
        >
          次へ
        </Button>
      </div>
    </dialog>
  );
};
