import { tlReadyExecute } from '@doda/common/src/utils/adobeAnalyticsUtil';
import { dataLayer } from '@doda/common/src/utils/googleTagManagerUtil';
import { Dispatch } from '@reduxjs/toolkit';

/**
 * 初回ヒアリング: 希望の勤務地ダイアログ「次へ」クリック計測
 * @param {boolean} isAdobeScript - AAスクリプト読み込みステータス
 * @param {Dispatch} dispatch
 */
export const measureOnClickLocation = (
  isAdobeScript: boolean,
  dispatch: Dispatch
) => {
  tlReadyExecute(isAdobeScript, dispatch, 'onbord_req_area_next', {
    linkTrackVars: 'prop23,eVar23',
    prop23: 'onbord_req_area_next',
    eVar23: 'D=c23',
  });
};

/**
 * 初回ヒアリング: 希望の職種ダイアログ「次へ」クリック計測
 * @param {boolean} isAdobeScript - AAスクリプト読み込みステータス
 * @param {Dispatch} dispatch
 */
export const measureOnClickOccupation = (
  isAdobeScript: boolean,
  dispatch: Dispatch
) => {
  tlReadyExecute(isAdobeScript, dispatch, 'onbord_req_job_next', {
    linkTrackVars: 'prop23,eVar23',
    prop23: 'onbord_req_job_next',
    eVar23: 'D=c23',
  });
};

/**
 * 初回ヒアリング: 希望の年収ダイアログ「次へ」クリック計測
 * @param {boolean} isAdobeScript - AAスクリプト読み込みステータス
 * @param {Dispatch} dispatch
 */
export const measureOnClickIncome = (
  isAdobeScript: boolean,
  dispatch: Dispatch
) => {
  tlReadyExecute(isAdobeScript, dispatch, 'onbord_req_income_next', {
    linkTrackVars: 'prop23,eVar23',
    prop23: 'onbord_req_income_next',
    eVar23: 'D=c23',
  });
};

/**
 * 再ヒアリング: 「希望条件を変更する」クリック計測
 * @param {boolean} isAdobeScript - AAスクリプト読み込みステータス
 * @param {Dispatch} dispatch
 */
export const measureOnClickChange = (
  isAdobeScript: boolean,
  dispatch: Dispatch
) => {
  tlReadyExecute(isAdobeScript, dispatch, 'jobRequirementsList_update', {
    linkTrackVars: 'prop1,prop23,prop69,eVar23,eVar78',
    linkTrackEvents:
      'event5,event8,event70,event71,event72,event73,event74,event81,event82,event135,event137,event139',
    prop23: 'jobRequirementsList_update',
    eVar23: 'jobRequirementsList_update',
    events: 'prop23,eVar23',
  });
  dataLayer({
    click_Label: 'prop23,eVar23',
    click_Action: 'jobRequirementsList_update',
    click_Category: 'jobRequirementsList_update',
    event: 'setEffect',
  });
};
