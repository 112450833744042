import { Text } from '@doda/io-react/common';
import { JobType } from '@doda/common/src/components/DesiredConditionsModal/type';
import { useMatchJobCount } from '@doda/common/src/components/DesiredConditionsModal/hooks/useMatchJobCount';
import styles from './index.module.scss';

/** CountコンポーネントのProps */
interface Props {
  /**
   * 加工した職種データ
   */
  jobCategories: JobType[];

  /**
   * cookie情報を選択項目へ変換するための関数を呼び出す
   *
   * @returns {Promise<void>}
   */
  prepare: () => Promise<void>;
}

/**
 * 検索条件で絞り込んだ件数を表示する
 *
 * @param {Props} props 加工した職種データ
 */
export const Count: React.FC<Props> = ({ jobCategories, prepare }: Props) => {
  const { count } = useMatchJobCount({ jobCategories, prepare });

  return (
    <div className={styles.count}>
      <Text
        color="black_pc"
        as="span"
        size="14--articlePC"
        className={styles.count__text}
      >
        現在
        <output>
          <Text
            color="red_pc"
            as="span"
            weight="bold"
            size="16"
            className={styles.count__middle__text}
          >
            {count.toLocaleString()}
          </Text>
        </output>
        <Text
          color="black_pc"
          as="span"
          weight="bold"
          size="16"
          className={styles.count__text__middle}
        >
          件
        </Text>
        がマッチしています。
      </Text>
      <Text
        color="black_pc"
        size="14--articlePC"
        className={styles.count__text}
      >
        希望条件をご確認ください。
      </Text>
    </div>
  );
};
