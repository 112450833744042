import { Dispatch, SetStateAction, createContext } from 'react';
import { Selection } from '../type';

/**
 * 選択した職種項目
 */
export const OccupationContext = createContext<
  [Selection[], Dispatch<SetStateAction<Selection[]>>]
>([[], () => {}]);

/**
 * 選択した勤務地項目
 */
export const LocationContext = createContext<
  [Selection[], Dispatch<SetStateAction<Selection[]>>]
>([[], () => {}]);

/**
 * 選択した希望年収項目
 */
export const SalaryContext = createContext<
  [Selection, Dispatch<SetStateAction<Selection>>]
>([{ name: '', value: '' }, () => {}]);

/**
 * 件数
 */
export const CountContext = createContext<
  [number, Dispatch<SetStateAction<number>>]
>([0, () => {}]);
