import { useContext, useEffect } from 'react';
import {
  CountContext,
  LocationContext,
  OccupationContext,
  SalaryContext,
} from '../logics/context';
import { jobCount } from '../logics/function/fetch';
import { JobType } from '../type';

interface Props {
  /**
   * 加工した職種データ
   */
  jobCategories: JobType[];

  /**
   * cookie情報を選択項目へ変換するための関数を呼び出す
   *
   * @returns {Promise<void>}
   */
  prepare: () => Promise<void>;
}

/**
 * 希望条件モーダルの求人件数を管理する
 */
export const useMatchJobCount = ({ jobCategories, prepare }: Props) => {
  const [count, setCount] = useContext(CountContext);
  const [occupations] = useContext(OccupationContext);
  const [locations] = useContext(LocationContext);
  const [salary] = useContext(SalaryContext);

  useEffect(() => {
    const fetchPrepare = async () => {
      await prepare();
    };
    fetchPrepare();
    // cookieの読み込みから選択結果の反映は1回のみ
    // 以降の選択は、context経由で行うので1回のuseEffectで問題ない
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true;
    /**
     * 選択した項目を基にした件数取得APIの結果を提供する
     */
    const fetchJobCount = async () => {
      try {
        const response = await jobCount(
          occupations,
          locations,
          salary,
          jobCategories
        );
        if (isMounted) setCount(response);
      } catch (error) {
        if (isMounted) setCount(0);
      }
    };

    if (jobCategories.length > 0) {
      const fetch = async () => {
        await fetchJobCount();
      };
      fetch();
    }

    return () => {
      isMounted = false;
    };
    // 選択項目のみを依存配列にする
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [occupations, locations, salary]);

  return { count };
};
